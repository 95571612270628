import axios from "axios";

export const obtenerCotizacion = ({dimencionesTotal, pesoTotal, codigoDane, bodega, valorTotal, direccionEnvio}) => {


    // console.log(total)

    let data = JSON.stringify({
        "packages": [
            {
                "weight": pesoTotal,
                "height": dimencionesTotal.alto,
                "width": dimencionesTotal.ancho,
                "length": dimencionesTotal.fondo
            }
        ],
        "description": "Productos naturales",
        "contentValue": valorTotal,
        "origin": {
            "daneCode": bodega.codigo,
            "address": bodega.direccion
        },
        "destination": {
            "daneCode": codigoDane + '000',
            "address": direccionEnvio
        }
    });

    let config = {
        method: 'post',
        url: 'https://api.envioclickpro.com.co/api/v2/quotation',
        headers: {
            'Authorization': '4d5c550a-ca8f-46e9-9ec5-690b950ee08e',
            'Content-Type': 'application/json'
        },
        data: data
    };

    return new Promise((resolve) => {
        axios(config)
            .then(function (response) {
              //  console.log(response.data)
                if (response.status === 200) {
                    return resolve({res: true, data: response.data.data.rates})
                } else {
                    return resolve({res: false, data: "error interno"})
                }

            })
            .catch(function (error) {
                return resolve({res: false, data: error.message})
                //console.log(error)
            });

    })


}