/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import logo from '../Recursos/logoblanco.svg'
import {theme} from "../Tema";
import {SECCIONES} from "../Constantes";
import {useDialogo} from "../Modulo_dialogos/Hooks/useDialogo";
import Dialogo_Login from "./Dialogos/Dialogo_Login";
import {useNavigate} from 'react-router-dom'


const Footer = () => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Login})
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: "#555553", marginTop: 4}}
        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3, py: masSM ? 6 : 3}}
                spacing={2}
            >


                <Grid item lg={3} sm={3} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: "#D56800", fontSize: 18, fontWeight: 600, marginBottom: 1}}>Links
                                Rapidos</Typography>
                        </Grid>

                        {SECCIONES.map((it, index) => {
                            return (
                                <Grid key={`rsr${index}`} item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                                    <ButtonBase onClick={() => navigate('/' + it)}>
                                        <Typography
                                            sx={{color: "#fff", fontSize: 16, fontWeight: 400}}>{it}</Typography>
                                    </ButtonBase>
                                </Grid>
                            )
                        })}

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                            <ButtonBase
                                onClick={() => abrir()}
                            >
                                <Typography
                                    sx={{color: "#fff", fontSize: 16, fontWeight: 400}}>{'Administración'}</Typography>
                            </ButtonBase>
                        </Grid>


                    </Grid>
                </Grid>


                <Grid item lg={5} sm={5} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: "#D56800", fontSize: 18, fontWeight: 600}}>Datos</Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                            <Typography sx={{color: "#fff", fontSize: 16, fontWeight: 400}}>Distribuidora de productos
                                naturales (Dispronan)</Typography>
                        </Grid>
                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                            <Typography sx={{color: "#fff", fontSize: 16, fontWeight: 400}}>Nit :
                                80201492-4</Typography>
                        </Grid>
                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                            <Typography sx={{color: "#fff", fontSize: 16, fontWeight: 400}}>Carrera 71G # 117b-27
                                Bogotá</Typography>
                        </Grid>
                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                            <Typography sx={{color: "#fff", fontSize: 16, fontWeight: 400}}>
                                +57 312 368 8893</Typography>
                        </Grid>
                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                            <Typography sx={{color: "#fff", fontSize: 16, fontWeight: 400}}>+57 310 310
                                2982 </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>

                    <img src={logo} width={"50%"}/>
                </Grid>


            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'center', backgroundColor: '#C3C700', p: 1}}>
                <Typography sx={{color: '#000'}}>© Copyright 2024 - 2025 | Dispronan</Typography>
            </Grid>


        </Grid>
    )
}

export default Footer;