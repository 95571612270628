/**************************************************
 * Nombre:       Detalle_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import LogosFraces from "./ComponentesSecciones/LogosFraces";
import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useState} from "react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {irArriba} from "../../Utilidades/irArriba";
import {ShoppingCart, TaskAlt} from "@mui/icons-material";
import {useFuncionesCarrito} from "../../Modulo_carrito_compras/Hooks/useFuncionesCarrito";
import {useCarritoCompras} from "../../Modulo_carrito_compras/Hooks/useCarritoCompras";

const Detalle_Producto = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const parms = useParams()
    const [producto, setProducto] = useState({})
    const {adicionarProducto, verificarProductoCarrito} = useFuncionesCarrito()
    const {abrir, DialogoCarrito} = useCarritoCompras()
    const navigate = useNavigate()

    useEffect(() => {

        irArriba()

        if (parms && parms.id) {
            obtenerDoc('productos', parms.id).then((dox) => {
                if (dox.res && dox.data) {
                    setProducto(dox.data)
                } else {
                    navigate('/')
                }
            })
        }


    }, [parms])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <DialogoCarrito/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}

            >

                <Grid item container lg={10} sm={12} xs={12} sx={{marginTop: 10}}>


                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >


                        <Grid item container lg={5} sm={4} xs={12}
                              sx={{justifyContent: 'flex-start', border: 12, borderColor: '#C3C700', borderRadius: 6}}>
                            <img src={producto.img ? producto.img : ''} width={'100%'} height={'auto'}
                            />
                        </Grid>

                        <Grid item container lg={7} sm={8} xs={12}
                              sx={{justifyContent: 'flex-start', pl: sCell ? 0 : 6, marginTop: sCell ? 4 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >


                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography component={"h1"}
                                                sx={{
                                                    fontSize: 32,
                                                    fontWeight: 700,
                                                    lineHeight: 1.3
                                                }}>{producto.nombre}
                                    </Typography>
                                </Grid>


                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontWeight: 400,
                                            lineHeight: 1.4,
                                        }}>
                                        {producto.descripcion}
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >


                                        {producto && producto.beneficio1 &&

                                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: 16,
                                                        color: '#000000',
                                                        display: '-webkit-box',
                                                        overflow: 'hidden',
                                                        WebkitBoxOrient: 'vertical',
                                                        WebkitLineClamp: 1,
                                                        textOverflow: "ellipsis",
                                                    }}>


                                                    {"• " + producto.beneficio1}</Typography>
                                            </Grid>
                                        }

                                        {producto && producto.beneficio2 &&

                                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: 16,
                                                        color: '#00000090',
                                                        display: '-webkit-box',
                                                        overflow: 'hidden',
                                                        WebkitBoxOrient: 'vertical',
                                                        WebkitLineClamp: 1,
                                                        textOverflow: "ellipsis",
                                                    }}>


                                                    {"• " + producto.beneficio2}</Typography>
                                            </Grid>
                                        }

                                        {producto && producto.beneficio3 &&

                                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: 16,
                                                        color: '#00000090',
                                                        display: '-webkit-box',
                                                        overflow: 'hidden',
                                                        WebkitBoxOrient: 'vertical',
                                                        WebkitLineClamp: 1,
                                                        textOverflow: "ellipsis",
                                                    }}>


                                                    {"• " + producto.beneficio3}</Typography>
                                            </Grid>
                                        }

                                        {producto && producto.beneficio4 &&

                                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: 16,
                                                        color: '#00000090',
                                                        display: '-webkit-box',
                                                        overflow: 'hidden',
                                                        WebkitBoxOrient: 'vertical',
                                                        WebkitLineClamp: 1,
                                                        textOverflow: "ellipsis",
                                                    }}>


                                                    {"• " + producto.beneficio4}</Typography>
                                            </Grid>
                                        }

                                    </Grid>
                                </Grid>

                                {producto.rebajado > 0 &&
                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{
                                              justifyContent: 'flex-start',
                                              marginTop: sCell ? 1 : 2,
                                              marginBottom: -2.5
                                          }}>
                                        <Typography
                                            sx={{
                                                color: '#7e7e7e',
                                                fontSize: 14,
                                                fontWeight: 600,
                                                textDecoration: 'line-through'
                                            }}>
                                            {`Antes ${formatoMoneda(producto.valor)}`}
                                        </Typography>
                                    </Grid>
                                }

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Typography
                                        sx={{color: theme.palette.secondary.main, fontSize: 32, fontWeight: 600}}>
                                        {producto.rebajado > 0 ? formatoMoneda(producto.rebajado) : formatoMoneda(producto.valor)}
                                    </Typography>
                                </Grid>


                                <Grid item container lg={11} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 4}}>

                                    {verificarProductoCarrito(producto) ?

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >

                                            <Grid item container lg={6} sm={6} xs={6}
                                                  sx={{justifyContent: 'flex-start'}}>
                                                <Typography sx={{
                                                    fontSize: 18,
                                                    color: '#00000080',
                                                    fontWeight: 600
                                                }}>{'El producto ya se encuentra en el carrito de compras'}</Typography>
                                            </Grid>

                                            <Grid item container lg={6} sm={6} xs={6}
                                                  sx={{justifyContent: 'flex-start', marginTop: 0}}>
                                                <Button
                                                    onClick={() => abrir()}
                                                    startIcon={<TaskAlt/>}
                                                    sx={{px: 3, py: sCell ? 2 : 1.5}} fullWidth={sCell}>
                                                    Finalizar la compra
                                                </Button>
                                            </Grid>

                                        </Grid>


                                        :
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            spacing={2}
                                        >


                                            <Grid item container lg={6} sm={6} xs={12}
                                                  sx={{justifyContent: 'flex-start'}}>
                                                <Button
                                                    onClick={() => {
                                                        adicionarProducto(producto)
                                                        abrir()
                                                    }}
                                                    startIcon={<TaskAlt/>}
                                                    sx={{px: 6, py: sCell ? 2 : 1.5}} fullWidth={sCell}>
                                                    Comprar Ahora
                                                </Button>
                                            </Grid>


                                            <Grid item container lg={6} sm={6} xs={12}
                                                  sx={{justifyContent: 'flex-start', marginTop: sCell ? 2 : 0}}>
                                                <Button
                                                    onClick={() => adicionarProducto(producto)}
                                                    startIcon={<ShoppingCart/>}
                                                    fullWidth={sCell}
                                                    variant={'outlined'}
                                                    sx={{
                                                        color: theme.palette.secondary.main,
                                                        px: 5,
                                                        py: sCell ? 2 : 1.5
                                                    }}>
                                                    Agregar al Carrito
                                                </Button>
                                            </Grid>


                                        </Grid>

                                    }
                                </Grid>


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 10, maxWidth: '1400px', px: 3}}>
                <LogosFraces/>
            </Grid>

        </Grid>
    )

}
export default Detalle_Producto

