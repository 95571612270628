/**************************************************
 * Nombre:       Seccion_Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     24 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import {LocalMall, NoteAltOutlined} from "@mui/icons-material";
import {contarPropiedad} from "../../Servicios/BD/Contadores/contarPropiedad";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import Dialogo_Producto from "../Dialogos/Dialogo_Producto";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import {theme} from "../../Tema";
import {CATEGORIAS, ESTADOS} from "../../Constantes";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import Dialogo_Pedido from "../Dialogos/Dialogo_Pedido";
import {contarPropiedadSumaMes} from "../../Servicios/BD/Contadores/contarPropiedadSumaMes";
import {obtenerMesYearAnterior} from "../../Modulo_Graficas/Utilidades/obtenerMesYearAnterior";
import {adaptador_pedido} from "../../Modulo_carrito_compras/Adaptadores/adaptador_pedido";
import {adaptador_indicador} from "../../Modulo_Graficas/Adaptadores/adaptador_indicador";

const Seccion_Pedidos = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'pedidos',
        filtroInicial: 'fecha',
        nombreEstado: 'estado',
    })
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Pedido, realizado: () => setRecargar(!recargar)})
    const [valoresPedidos, setValoresPedidos] = useState([])


    const llenarValores = () => {

        let hoy = new Date()
        let mes = hoy.getMonth()
        let year = hoy.getFullYear()

        let valorActual = 0
        let valorAnterior = 0

        let fechaAnterior = obtenerMesYearAnterior({year: year, mes: mes})


        contarPropiedadSumaMes({coleccion: 'pedidos', propiedad: 'pagado', mes: mes, year: year}).then((dox) => {
            if (dox.res) {
                valorActual = dox.data

                contarPropiedadSumaMes({
                    coleccion: 'pedidos',
                    propiedad: 'pagado',
                    mes: fechaAnterior.mes,
                    year: fechaAnterior.year
                }).then((dox) => {
                    if (dox.res) {
                        valorAnterior = dox.data

                        let valores = adaptador_indicador({
                            titulo: 'Valor Pedidos',
                            valorAnterior: valorAnterior,
                            valorActual: valorActual,
                            detalle: 'vs mes anterior'
                        })

                        setValoresPedidos([valores])

                    }
                })


            }
        })


    }

    useEffect(() => {

        contarPropiedad({coleccion: 'pedidos', propiedad: 'estado', valor: 'nuevo'}).then((dox) => {
            if (dox.res) {
                setNumProductos(dox.data)
            }
        })


        llenarValores()

        cargarNuevamente()


    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >

            <Dialogo/>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Pedidos'} textoComplementario={'nuevos'}
                                      textoResaltado={`${numProductos} Pedidos`}/>
            </Grid>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                <Indicadores valores={valoresPedidos} dir={'flex-end'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabeceraPc}
                    {...props}
                    click={abrir}
                    estados={ESTADOS}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Pedidos

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabeceraPc = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Apellido',
        direccion: 'left',
        propiedad: 'apellido',

    },
    {
        nombre: 'Pago',
        direccion: 'left',
        propiedad: 'estadoPago',

    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',

    },
    {
        nombre: 'Ciudad',
        direccion: 'right',
        propiedad: 'ciudad',

    }, {
        nombre: 'Valor Cancelado',
        direccion: 'right',
        propiedad: 'pagado',
        tipo: 'moneda'
    },
]

const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Pago',
        direccion: 'left',
        propiedad: 'estadoPago',

    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',

    }
]

