/**************************************************
 * Nombre:       Dialogo_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Productos from "../Formularios/Formulario_Productos";
import {LoadingButton} from "@mui/lab";
import {Delete, LocalMall, LocalShipping, TaskAlt} from "@mui/icons-material";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useEffect, useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {obtenerDescuento} from "../../Utilidades/obtenerDescuento";
import Formulario_Pedido from "../Formularios/Formulario_Pedido";

const Dialogo_Pedido = ({cerrar, realizado, datos = {formato: '1:1'}}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const [cargando, setCargando] = useState(false)
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardar = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo Datos')
            let obj = entidad

            guardarDoc('pedidos', obj).then((dox) => {
                if (dox.res) {
                    realizado()
                    cerrar()
                } else {
                    alert('Ups algo inesperado paso al subir el producto, por favor intentalo mas tarde')
                }
                cerrarCargador()
            })


        })
    }

    const borrar = () => {
        abrirCargador('Borrando pedidos')
        borrarDoc('pedidos', datos.id).then((dox) => {
            cerrarCargador()
            realizado()
        })
    }

    const colocarAnuncio = () => {

        abrirCargador('Colocando producto como anuncio')
        obtenerEntidad().then((entidad) => {
            let obj = entidad
            obj.idProducto = entidad.id
            obj.id = 'anuncio'
            guardarDoc('anuncio', obj).then((dox) => {
                cerrarCargador()
                if (dox.res) {
                    alert('Producto colocado como anuncio')
                } else {
                    alert('Algo inesperado paso, por favor intentalo nuevamente')
                }
            })
        })

    }

    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Pedido props={props} click={colocarAnuncio} id={datos.id} realizado={realizado}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', mt: 3, mb: 1}}>
                <LoadingButton
                    loading={cargando}
                    onClick={() => guardar()}
                    startIcon={<TaskAlt/>}
                    color={'primary'}
                    variant={'contained'}
                >
                    {'Actualizar Pedido'}
                </LoadingButton>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                {datos && datos.id &&
                    <Button
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar Pedido</Button>
                }
            </Grid>


        </Grid>

    )

}
export default Dialogo_Pedido